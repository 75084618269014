document.addEventListener("DOMContentLoaded", () => {
    loadElements();
});

function loadElements() {
    document.querySelectorAll(".blockquote p:first-child").forEach(line => {
        const span = document.createElement('span');
        line.prepend(span);
        const quoteSize = setSizeProperty(line, '20px');
        setColorProperty(line);
        if (quoteSize) {
            const blockQuote = line.closest('.blockquote')
            blockQuote.style.paddingLeft = (parseInt(quoteSize) * 1.5) + 'px'; 
        }
    });
    document.querySelectorAll(".blockquote p:last-child").forEach(line => {
        const span = document.createElement('span');
        line.append(span);
        setSizeProperty(line,'20px');
    });
    document.querySelectorAll(".blockquote-caption").forEach(caption => {
        setSizeProperty(caption);
    });
}

function setSizeProperty(el, defaultSize = '') {
    const targetel = el.querySelector(`[class^="fs-"], [class^="display-"], [class^="h"]`);
    const targetStyle = window.getComputedStyle(targetel);
    if (targetStyle) {
        const fontSize = targetStyle.fontSize;
        el.style.setProperty('--quote-size', fontSize);
        return fontSize;
    } else {
        el.style.setProperty('--quote-size', defaultSize);
        return defaultSize;
    }
}

function setColorProperty(el) {
    const targetel = el.querySelector(`[style^="color"], [class^="text-"]`);
    const targetStyle = window.getComputedStyle(targetel);
    if (targetStyle) {
        console.log(targetStyle.color);
        const color = targetStyle.color;
        el.style.setProperty('--quote-color', color);
    }
}