window.addEventListener("load", () => {
    let stickyNavs = document.querySelectorAll(".sticky-nav");
    if (stickyNavs) {
        prepareStickyNavScroll(stickyNavs)
    }
});

function prepareStickyNavScroll(stickyNavs) {
    stickyNavs.forEach( stickyNav => {

        // Override ul alignment to flex-start when total ul width is greater than nav width  
        const navWidth = stickyNav.offsetWidth;
        const ul = stickyNav.querySelector('ul');
        if (ul.scrollWidth > navWidth) {
            ul.style.setProperty('justify-content', 'flex-start', 'important');
        }

        let items = stickyNav.querySelectorAll('.item');
        let list = stickyNav.querySelector('.list');
        let leftBtn = stickyNav.querySelector('.left-scroll-btn');
        let rightBtn = stickyNav.querySelector('.right-scroll-btn');
        let toggleBtn = stickyNav.querySelector('.toggle-button');
        let toggleBtnLabel = toggleBtn.querySelector('.label');
        let toggleBtnDefaultLabel = toggleBtnLabel.textContent;
        let listWidth = list.offsetWidth;
        let scrollStep = listWidth/2;
        let maxScroll;
        let scroll = list.scrollLeft;
        let padding = "20px";
        let scrollDefaultOffset = 75;

        let checkDisplayButtons = () => {
            if(stickyNav.hasAttribute('controls')) {
                if (scroll >= maxScroll) {
                    rightBtn.classList.add('d-none');
                    stickyNav.style.paddingRight = 0;
                } else {
                    rightBtn.classList.remove('d-none');
                    stickyNav.style.paddingRight = padding;
                }
                if (scroll > 0) {
                    leftBtn.classList.remove('d-none');
                    stickyNav.style.paddingLeft = padding;
                } else {
                    leftBtn.classList.add('d-none');
                    stickyNav.style.paddingLeft = 0;
                }
            } else {
                leftBtn.classList.add('d-none');
                rightBtn.classList.add('d-none');
            }
        }

        let applyEllipsis = () => {
            list.querySelectorAll('.item').forEach( item => {
                let link = item.querySelector('a');
                let xPos = link.getBoundingClientRect().x - list.getBoundingClientRect().x;
                let linkWidth = link.offsetWidth - (xPos + link.offsetWidth - list.offsetWidth);
                if (!list.classList.contains('fixed-width') && !item.style.width) {
                    link['originalSize'] = link.offsetWidth;
                    if (linkWidth < link.originalSize) {
                        item.style.justifyContent = 'left'
                        item.style.width = item.offsetWidth + 1 + 'px';
                    }
                }
                if (!list.classList.contains('fixed-width') && linkWidth < link.originalSize) {
                    item.style.justifyContent = 'left';
                    link.style.width = linkWidth + 'px';
                } else {
                    item.style.justifyContent = '';
                    link.style.width = '';
                }
            });
        }

        let scrollTo = (hash) => {
            let section = document.querySelector(hash);
            if (section) {
                //Timeout to send the scroll action to the end of the queue to avoid conflict with the default anchor behavior
                setTimeout(() => {
                    window.scrollTo({top: section.offsetTop - scrollDefaultOffset, behavior: 'smooth'});
                }, 0);
            }
        }

        let scrollIfNotVisibleTo = (item) => {
            if (item.getBoundingClientRect().x + item.offsetWidth > window.innerWidth) {
                scroll += scrollStep;
                list.scrollTo({left: scroll, behavior: 'smooth'});
            } 
            if (item.getBoundingClientRect().x < 0) {
                scroll -= scrollStep;
                list.scrollTo({left: scroll, behavior: 'smooth'});
            } 
        }

        let checkIfSectionIsActive = (item) => {
            let fromTop = window.scrollY;
            let link = item.querySelector("a");
            if (link && link.hash) {
                let section = document.querySelector(link.hash);
                if(section) {
                    if (section.offsetTop - scrollDefaultOffset <= fromTop && section.offsetTop + section.offsetHeight - scrollDefaultOffset> fromTop) {
                        if (!link.classList.contains('active')) {
                            link.classList.add("active");
                            link.setAttribute('aria-current', true);
                            scrollIfNotVisibleTo(item);
                            toggleBtnLabel.textContent = link.text;
                            toggleBtnLabel.classList.add('fw-bold');
                        }
                    } else {
                        link.classList.remove("active");
                        link.removeAttribute('aria-current');
                        if(!list.querySelector('.active')) {
                            toggleBtnLabel.textContent = toggleBtnDefaultLabel;
                            toggleBtnLabel.classList.remove('fw-bold');
                        }
                    }
                }
            }
        }

        let isMobile = () => {
            return window.innerWidth < 768;
        }

        checkDisplayButtons();

        //Timeout to give the time to initialize correctly before appling transformations
        setTimeout(() => {
            stickyNav.style.transition = '0.5s';
            maxScroll = list.scrollWidth - listWidth;
            checkDisplayButtons();
            applyEllipsis();
        }, 0);

        list.addEventListener('scroll', () => {
            scroll = list.scrollLeft;
            checkDisplayButtons();
            applyEllipsis();
        });

        leftBtn.addEventListener('click', () => {
            scroll -= scrollStep;
            list.scrollTo({left: scroll, behavior: 'smooth'});
        });

        rightBtn.addEventListener('click', () => {
            scroll += scrollStep;
            list.scrollTo({left: scroll, behavior: 'smooth'});
        });

        toggleBtn.addEventListener('click', () => {
            if (list.style.display === "block"){
                list.style.display='none';
            } else {
                list.style.display = "block";
                list.querySelector("a").focus()
            }
        });

        //Listener to change the active status of the stickynav
        window.addEventListener("scroll", () => {
            items.forEach((item) => {
                checkIfSectionIsActive(item);
            });
        });
    
        items.forEach(item => {
            let link = item.querySelector("a");
            //this listener translate the behavior of the list item to the anchor
            item.addEventListener("click", () => {
                link.click();
            });
            link.addEventListener("click", (e) => {
                e.stopPropagation();
            });
            if (link) {
                link.addEventListener("focus", () => {scrollIfNotVisibleTo(item)});
                link.addEventListener("blur", (e) => {
                    if(!list.contains(e.relatedTarget)) {
                        list.style.display = "none";
                    }
                });
                if(link.hash) {
                    link.addEventListener("click", (e) => {
                        let hash = e.target.hash;
                        scrollTo(hash);
                        if(isMobile()){
                            list.style.display = "none";
                        }
                    });
                }
            }
        });
    });
    
}

