function debounce(func, delay) {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), delay);
  };
}

const debouncedMobAccordion = debounce(() => {
  convertMobAccordion();
}, 250);

const debouncedinitAccordionsTabs = debounce(() => {
  initAccordionsTabs();
}, 250);

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
}

function init() {
  window.addEventListener('resize', debouncedMobAccordion);
  document.addEventListener('render', debouncedinitAccordionsTabs);
  initAccordionsTabs();
}

const keys = {
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13,
  space: 32,
};

function keyDownHandler(event, link, list, index) {
  const key = event.keyCode;
  if(!event.shiftKey){
    switch (key) {
      case keys.down:
        event.preventDefault();
        list[index+1]?.focus();
        break;
      case keys.right:
        event.preventDefault();
        list[index+1]?.focus();
        break;
      case keys.up:
        event.preventDefault();
        list[index-1]?.focus();
        break;
      case keys.left:
        event.preventDefault();
        list[index-1]?.focus();
        break;
      case keys.end:
        event.preventDefault();
        list[list.length-1].focus();
        break
      case keys.home:
        event.preventDefault();
        list[0].focus();
        break
      case keys.enter:
        event.preventDefault();
        moveIndex(link);
        link.click();
        break
      case keys.space:
        event.preventDefault();
        moveIndex(link);
        link.click();
        break
      default:
        break;
    }
  }
}

function moveIndex(link) {
  let focusTabs;
  let bs5TabsId = link.closest('.bs5Tabs').id;

  if(link.classList.contains('nav-link')){
    focusTabs = document.querySelectorAll('#' + bs5TabsId + ' > .nav > .nav-item > .nav-link:not([tabindex="-1"])');
  } else {
    focusTabs = document.querySelectorAll('#' + bs5TabsId + ' > .tab-content > .tab-pane > .accordion-button:not([tabindex="-1"])');
  }

  focusTabs.forEach(tab => {
    tab.setAttribute('tabIndex', '-1');
  });
  link.setAttribute('tabIndex', '0');
}

function initAccordionsTabs() {
  const url = new URL(window.location.href);
  const accordionContainers = document.querySelectorAll('.bs5Tabs');
  accordionContainers.forEach((accordionContainer) => {
    const links = accordionContainer.querySelectorAll('.nav>.nav-item>.nav-link');
    links.forEach((link, index) => {
      link.addEventListener('click', () => {
        moveIndex(link);
        url.hash = '#' + link.id;
        history.pushState({}, null, url.href);
      });
      link.addEventListener('keydown', (event) => {
        keyDownHandler(event, link, links, index);
      });
    });
    const accordionList = accordionContainer.querySelectorAll('.accordion-button');
    accordionList.forEach((link, index) => {
      link.addEventListener('keydown', (event) => {
        keyDownHandler(event, link, accordionList, index);
      });
    });
  });
  convertMobAccordion();
}

function convertMobAccordion() {
  const accordionContainers = document.querySelectorAll('.mob-accordion');
  accordionContainers.forEach((accordionContainer) => {
    const isVertical = accordionContainer.classList.contains('vertical');
    const isBorderless = accordionContainer.classList.contains('tab-style-secondary') | accordionContainer.classList.contains('tab-style-pills') ;
    const accordions = accordionContainer.querySelectorAll('.tab-pane');
    if (window.innerWidth < 768) {
      if (!isBorderless) {
        accordionContainer.classList.remove(isVertical ? 'border-start' : 'border-top');
      }
      accordions.forEach((accordion) => {
        accordion.classList.add('accordion-item');
        accordion.classList.add('d-block');
        accordion.classList.add('opacity-100');
        const collapse = accordion.querySelector('.accordion-collapse');
        if (collapse) {
          collapse.classList.remove('d-block');
        }
      });
    } else {
      if (!isBorderless) {
        accordionContainer.classList.add(isVertical ? 'border-start' : 'border-top');
      }
      accordions.forEach((accordion) => {
        accordion.classList.remove('accordion-item');
        accordion.classList.remove('d-block');
        accordion.classList.remove('opacity-100');
        const collapse = accordion.querySelector('.accordion-collapse');
        if (collapse) {
          collapse.classList.add('d-block');
        }
      });
    }
  });
}