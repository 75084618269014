window.addEventListener('DOMContentLoaded', () => {
    _handleMultiNavbarCollapse();
});

/**
 * When click on the dropdown item, this function will autoclose the navbar window on mobile view
 */
function _handleMultiNavbarCollapse() {
    const navItems = document.querySelectorAll('.multinav-dropdown-item');
    navItems.forEach((navItem) => {
        navItem.addEventListener('click', () => {
            const menuToggle = document.querySelector('.navbar-collapse.shadow.collapse.show');
            if (menuToggle) {
                const bsCollapse = new bootstrap.Collapse(menuToggle, { toggle: false });
                bsCollapse.toggle();
                const navbar = document.querySelector('[aria-controls="navbarSupportedContent"]');
                const spanContainer = navbar.firstElementChild;
                spanContainer.firstElementChild.setAttribute('icon', 'chevron-down');
            }
        });
    });
}
