if (document.readyState !== "loading") {
    handleTabOrientationSync();
    handleTabA11y();
} 
else {
    document.addEventListener("DOMContentLoaded", () => {
        handleTabOrientationSync();
        handleTabA11y();
    });
}

/**
 * When a tab in desktop is clicked, expand the corresponding mobile tab and vice versa. 
 * This is to keep the desktop and mobile tabs in sync (useful in device orientation change).
 */
function handleTabOrientationSync() {
    _addDesktopTabsEventListener();
    _addMobileTabsEventListener();
}

function _addDesktopTabsEventListener() {
    const tabs = document.querySelectorAll('a[role="tab"]:not(.bs5Tabs-tab-links)');
    tabs.forEach(tab => tab.addEventListener('click', _activateMobileCollapse));

    function _activateMobileCollapse(event) {
        const isMobile = window.screen.width <= 768;
        if (!isMobile) {
            const tab = event.target.closest('.nav-link');
            if (tab.id) {
                const targetCollapse = document.querySelector(document.getElementById('mob-tab-' + tab.id)?.getAttribute('data-bs-target'));
                if(targetCollapse){
                    const parentCollapse = targetCollapse.closest(".tab-content");
                    parentCollapse.querySelectorAll('div[role="tabpanel"] > div[role="tabpanel"]').forEach(el => el.classList.remove("show"));
                    parentCollapse.querySelectorAll('.card-header > a').forEach(el => el.classList.add("collapsed"));
                    targetCollapse.classList.add("show");
                    targetCollapse.parentElement.querySelector("div.card-header > a").classList.remove("collapsed");
                }
            }
        }
    }
}

function _addMobileTabsEventListener() {
    const mobileTabs = document.querySelectorAll('a.mob-tab-link');
    mobileTabs.forEach(mobileTab => mobileTab.addEventListener('click', _activateDesktopCollapse));
    
    function _activateDesktopCollapse(event) {
        const collapse = event.target.closest('.mob-tab-link');
        if (collapse.id) {
            const targetTabId = collapse.id.replace('mob-tab-', '');
            const targetTab = document.getElementById(targetTabId);
            const tab = bootstrap.Tab.getOrCreateInstance(targetTab);
            tab.show();
        }
    }
}

function checkElementMatchesSelector(element, selector, callback){
    if(element.matches(selector))
        callback();
}

/** To handle accessibility compliance for tabs */
function handleTabA11y() {
    const tabs = 'a[role="tab"]:not(.bs5Tabs-tab-links)';
    
    document.addEventListener("click", (e) => {
        if(e instanceof CustomEvent){
            return;
        }
        
        checkElementMatchesSelector(e.target, tabs, () => clickEventListener(e));
    });

    document.addEventListener("keydown", (e) => {
        checkElementMatchesSelector(e.target, tabs, () => keydownEventListener(e));
    } );
    document.addEventListener("keyup", (e) => {
        checkElementMatchesSelector(e.target, tabs, () => keyupEventListener(e));
    });

    // keys for reference
    const keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46,
        enter: 13,
        space: 32
    };

    // Add or subtract depending on key pressed
    const direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    };

    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener ({target}) {
        var tab = target.closest('.nav-link');
        var tabContainerId;

        if(target.closest('.horizontal-tabs')) {
            tabContainerId = target.closest('.horizontal-tabs').id;
        } else if (target.closest('.vertical-tabs')) {
            tabContainerId = target.closest('.vertical-tabs').id;
        }
        activateTab(tab, tabContainerId, false);
    };

    // Activates any given tab panel
    function activateTab (tab, tabContainerId, _setFocus) {
        // Deactivate all other tabs
        deactivateTabs(tabContainerId);

        // Remove tabindex attribute from selected tab
        tab.removeAttribute('tabindex');
        // Activate the selected tab panel
        const triggerEl = document.querySelector('#'+tab.id);
        const tabTrigger = bootstrap.Tab.getOrCreateInstance(triggerEl);
        tabTrigger.show();
    };

    // Deactivate all tabs in the same tab container
    function deactivateTabs (tabContainerId) {
        var siblingTabs = document.querySelectorAll('#'+tabContainerId + ' a[role="tab"]');
        for (var t = 0; t < siblingTabs.length; t++) {
            siblingTabs[t].setAttribute('tabindex', '-1');
        }
    };

    // Handle keydown on tabs
    function keydownEventListener (event) {
        var key = event.keyCode;
        if(event.target.closest('.horizontal-tabs')) {
            var tabContainerId = event.target.closest('.horizontal-tabs').id;
        }
        else if (event.target.closest('.vertical-tabs')) {
            var tabContainerId = event.target.closest('.vertical-tabs').id;
        }
        switch (key) {
            case keys.end:
                event.preventDefault();
                // Activate last tab
                focusLastTab();
                break;
            case keys.home:
                event.preventDefault();
                // Activate first tab
                focusFirstTab(tabContainerId);
                break;

            // Up and down are in keydown because we need to prevent page scroll
            case keys.up:
            case keys.down:
                determineOrientation(event);
                break;

            case keys.space:
                event.preventDefault();
        };
    };

    // Handle keyup on tabs
    function keyupEventListener (event) {
        var key = event.keyCode;
        if(event.target.closest('.horizontal-tabs')) {
            var tabContainerId = event.target.closest('.horizontal-tabs').id;
        }
        else if (event.target.closest('.vertical-tabs')) {
            var tabContainerId = event.target.closest('.vertical-tabs').id;
        }
        switch (key) {
            case keys.left:
            case keys.right:
                determineOrientation(event);
                break;
            case keys.space:
            case keys.enter:
                activateTab(event.target, tabContainerId);
                break;
        };
    };

    // When a tablist's aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    function determineOrientation (event) {
        var key = event.keyCode;
        if(event.target.closest('.horizontal-tabs')) {
            var tabContainerId = event.target.closest('.horizontal-tabs').id;
        }
        else if (event.target.closest('.vertical-tabs')) {
            var tabContainerId = event.target.closest('.vertical-tabs').id;
        }
        var tabList = document.querySelector('#'+tabContainerId + " ul[role='tablist']");
        var vertical = tabList.getAttribute('aria-orientation') == 'vertical';
        var proceed = false;

        if (vertical) {
            if (key === keys.up || key === keys.down) {
                event.preventDefault();
                proceed = true;
            };
        }
        else {
            if (key === keys.left || key === keys.right) {
                proceed = true;
            };
        };

        if (proceed) {
            switchTabOnArrowPress(event, tabContainerId);
        };
    };

    // Either focus the next, previous, first, or last tab depending on key pressed
    function switchTabOnArrowPress (event, tabContainerId) {
        var pressed = event.keyCode;
        if (direction[pressed]) {
            var target = event.target;
            var tabsList = document.querySelectorAll('#'+tabContainerId + ' a[role="tab"]');
            for(var index = 0; index < tabsList.length; index++) {
                tabsList[index].index=index;
            }
            if (target.index !== undefined) {
                if (tabsList[target.index + direction[pressed]]) {
                    tabsList[target.index + direction[pressed]].focus();
                }
                else if (pressed === keys.left || pressed === keys.up) {
                    focusLastTab(tabContainerId);
                }
                else if (pressed === keys.right || pressed == keys.down) {
                    focusFirstTab(tabContainerId);
                };
            };
        };
    };

    // Move focus to first tab
    function focusFirstTab (tabContainerId) {
        var firstTab = document.querySelectorAll('#'+tabContainerId + ' a[role="tab"]')[0];
        firstTab.focus();
    };

    // Move focus to last tab
    function focusLastTab (tabContainerId) {
        var tabLength = document.querySelectorAll('#'+tabContainerId + ' a[role="tab"]').length;
        var lastTab = document.querySelectorAll('#'+tabContainerId + ' a[role="tab"]')[tabLength-1];
        lastTab.focus();
    };
}