/**
 * Add event listener to append accordion header ID of selected accordion item.
 */
document.addEventListener('DOMContentLoaded', () => {
  initAccordions();
});

function deeplink(accordionCollapse) {
  let currentUrl = window.location.href;
  const accordionHeader = accordionCollapse.closest('.accordion-header');
  let id = accordionHeader.getAttribute('id');
  // Tab components are automatically converted to accordions in mobile breakpoints.
  // To avoid duplicated ids, "accordion-" prefix is added to accordion ids.
  if (id.startsWith("accordion-")) {
    id = id.slice(10);
  }
  if (currentUrl.indexOf('#') !== -1) {
    currentUrl = currentUrl.substring(0, currentUrl.indexOf('#'));
  }
  history.pushState({}, null, currentUrl + '#' + id);
}

//this functions calculates the offset to scroll when sticky elements are present at the top of the screen
function calculateTopScrollOffset() {
  const stickyTopElements = document.querySelectorAll('.sticky-top');
  let maxHeight = 0; 
  stickyTopElements.forEach( element => {
      const elementBoundingClient = element.getBoundingClientRect();
      const elementHeight = elementBoundingClient.top === 0 ? elementBoundingClient.height : 0;
      if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
      } 
  })
  return maxHeight;
}

function scrollToElement(element) {
    let scrollOffset = calculateTopScrollOffset() + 5;
    if (element) {
      window.scrollTo({top: element.offsetTop - scrollOffset, behavior: 'smooth'});
    }
}

function scrollHandler(accordionButton) {
  //timeout to wait for the animation ends
    setTimeout(() => {
      //condition to check if the button its outside of the screen 
        if (accordionButton.getBoundingClientRect().top < 0) {
            scrollToElement(accordionButton);
        }
    }, 500);
}

function initAccordions() {
    document.querySelectorAll('.accordion-button').forEach((accordionCollapse) => {
      accordionCollapse.addEventListener('click', () => {
        deeplink(accordionCollapse);
        scrollHandler(accordionCollapse);
      });
    });
}
  
