if (document.readyState == 'complete') {
    initilizePurchaseOptionsTabs();
} else {
    window.addEventListener('load', function () {
        initilizePurchaseOptionsTabs();
    });
}

function debounce(func, delay) {
    let timeoutId;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
}

function scrollListener(tabContainer, scrollLeft, maxScroll, btnLeft, btnRight) {
    if(btnRight){
        if (scrollLeft >= maxScroll * 0.95) {
            btnRight.classList.add('d-none');
            tabContainer.style.paddingRight = 0;
        } else {
            btnRight.classList.remove('d-none');
            tabContainer.style.paddingRight = btnRight.getBoundingClientRect().width + 'px';
        }
    }
    if(btnLeft){
        if (scrollLeft > 0) {
            btnLeft.classList.remove('d-none');
            tabContainer.style.paddingLeft = btnLeft.getBoundingClientRect().width + 'px';
        } else {
            btnLeft.classList.add('d-none');
            tabContainer.style.paddingLeft = 0;
        }
    }
}

function initilizePurchaseOptionsTabs() {
    const tabsContainers = document.querySelectorAll('.tabs-container')
    tabsContainers.forEach( tabContainer => {
        const tabList = tabContainer.querySelector('ul.nav-tabs');
        const btnLeft = tabContainer.querySelector('.left-scroll-btn');
        const btnRight = tabContainer.querySelector('.right-scroll-btn');
        const scrollStep =  tabList.scrollWidth / 1.5;
        let maxScroll = tabList.scrollWidth - tabList.offsetWidth;
        if(maxScroll == 0) {
            setTimeout(function(){
                maxScroll = tabList.scrollWidth - tabList.offsetWidth;
                showRightArrow(tabContainer, tabList.scrollLeft, maxScroll, btnRight);
            }, 1000);
        }
        scrollToActiveTab(tabList);
        showRightArrow(tabContainer, tabList.scrollLeft, maxScroll, btnRight);
        debounce(scrollListener(tabContainer, tabList.scrollLeft, maxScroll, btnLeft, btnRight), 0);
        tabList.addEventListener('scroll', () => {
            debounce(scrollListener(tabContainer, tabList.scrollLeft, maxScroll, btnLeft, btnRight), 0);
        });
        if(btnLeft){
            btnLeft.addEventListener('click', () => {
                tabList.scrollTo({left: tabList.scrollLeft - scrollStep, behavior: 'smooth'});
            });
        }
        if(btnRight){
            btnRight.addEventListener('click', () => {
                tabList.scrollTo({left: tabList.scrollLeft + scrollStep, behavior: 'smooth'});
            });
        }
    });
}

function scrollToActiveTab(tabList) {
    const navLink = tabList.querySelector('.nav-item:has(.nav-link.active)');
    tabList.scrollTo({left: navLink.offsetLeft, behavior: 'smooth'});
}

function showRightArrow(tabContainer, scrollLeft, maxScroll, btnRight) {
    if(scrollLeft < maxScroll * 0.95) {
        btnRight.classList.remove('d-none');
        tabContainer.style.paddingRight = btnRight.getBoundingClientRect().width + 'px';
    }
}
